/* / For mobile devices / */
@media (max-width: 767px) {
    /* / Add your styles for mobile devices here / */
    #containerLogin{
        background: linear-gradient(to right, rgba(132, 250, 176, 0.5), rgba(143, 211, 244, 0.5));

        height: 600px;
        padding: 20px;
    }
   /* #cardBodyLogin{
    height: 50vh
   } */
   #loginbottomtext{
    margin: 10px auto;
   }
  #containerLogin{
    height: 100vh;
  }
  }
  
  /* / For tablets / */
  @media (min-width: 768px) and (max-width: 991px) {
    /* / Add your styles for tablets here / */
    #cardBodyLogin{
      height: 500px;
      width: 500px;
      display: flex;
      flex-direction: column;
      gap: 10px;
    }
    #loginText{
      padding-bottom: 10px;
      padding-top: 53px;
    }
    
  }
  
  /* / For small laptops and desktops / */
  @media (min-width: 992px) and (max-width: 1199px) {
    /* / Add your styles for small laptops and desktops here / */
  }
  
  /* / For large laptops and desktops / */
  @media (min-width: 1200px) {
    /* / Add your styles for large laptops and desktops here / */
    #cardBodyLogin{
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      height: auto;
      width: auto;
    }
#main-login-card{
  width: 40vw;
}
    #paddingBody{
   padding-right: 3rem !important;
    padding-left: 3rem !important;
    }

    #containerLogin{
        background: linear-gradient(to right, rgba(132, 250, 176, 0.5), rgba(143, 211, 244, 0.5));
        height: 100vh;
    }
    #loginText{
        margin-bottom: 20px;
    }
    #loginbottomtext{
      margin-bottom: 10px;
    }
    /* #loginbottomtext{

        /* text-align: center; */
    
    #loginPara{
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 0;
    }

  }
  @media (min-width:1920) {
    #cardBodyLogin{
      display: flex;
    justify-content: center;
    gap: 30px;
    align-items: center;
    flex-direction: column;
    height: auto;
    width: auto;
    }
  }
  