

/* / / For mobile devices / / */
@media (max-width: 767px) {
  /* / / Add your styles for mobile devices here / / */

  .notediv {
    display: none;
  }
  #button-for-open-camera {
    display: none;
  }
  .main {
    background: linear-gradient(
      to right,
      rgba(132, 250, 176, 0.5),
      rgba(143, 211, 244, 0.5)
    );
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  #cardBody {
    height: "70vh";
    width: "70vw";
  }
  #signup-card {
    width: 84vw;
  }
  #errormessage {
    text-align: center;
    margin-top: -5px;
  }
}
@media (max-width: 991px) {
  .popup-wrapper {
    display: none !important;
  }
}
/* / / For tablets / / */
@media (min-width: 768px) and (max-width: 991px) {
  /* / / Add your styles for tablets here / / */
  #button-for-open-camera {
    display: none;
  }
  .popup-wrapper {
    display: none !important;
  }
  #containerLogin {
    height: 100vh;
  }
  .notediv {
    display: none;
  }
  form {
    height: 100%;
  }
  .main {
    height: 100vh;
  }
  .container-fluid {
    height: 100%;
  }
  .card-body {
    height: 557px;
    width: 600px;
  }
}

/* / / For small laptops and desktops / / */
@media (min-width: 992px) and (max-width: 1199px) {
  /* / / Add your styles for small laptops and desktops here / / */
  #button-for-open-camera {
    display: none;
  }
  .main {
    margin: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  .popup-wrapper {
    display: none !important;
  }
}

/* / / For large laptops and desktops / / */
@media (min-width: 1200px) {
  .choose-file{
    font-weight: 900;
   }
   #webcam{
    border-radius: 10px;
   }
  
  .camera-popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 999;
  }

  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: transparent;
    border: none;
    color: white;
    font-size: 2em;
    z-index: 999;
  }
  /* / / Add your styles for large laptops and desktops here / / */
  .capture-btn {
    margin-top: 5px;
    padding: 10px 25px;
    border: unset;
    border-radius: 15px;
    color: #212121;
    z-index: 1;
    background: #e8e8e8;
    position: relative;
    font-weight: 1000;
    font-size: 17px;
    -webkit-box-shadow: 4px 8px 19px -3px rgba(0,0,0,0.27);
    box-shadow: 4px 8px 19px -3px rgba(0,0,0,0.27);
    transition: all 250ms;
    overflow: hidden;
   }
   
   .capture-btn::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 0;
    border-radius: 15px;
    background-color: #212121;
    z-index: -1;
    -webkit-box-shadow: 4px 8px 19px -3px rgba(0,0,0,0.27);
    box-shadow: 4px 8px 19px -3px rgba(0,0,0,0.27);
    transition: all 250ms
   }
   
   .capture-btn:hover {
    color: #e8e8e8;
   }
   
   .capture-btn:hover::before {
    width: 100%;
   }
  #cardBody {
    height: "70vh";
    width: "32vw";
  }
  #errormessage {
    text-align: center;
    margin-top: -5px;
  }
  .main {
    background: linear-gradient(
      to right,
      rgba(132, 250, 176, 0.5),
      rgba(143, 211, 244, 0.5)
    );
    display: flex;
    justify-content: center;
    align-items: center;
    height: auto;
  }
  .gradient-custom-3 {
    /* / fallback for old browsers / */
    background: #84fab0;

    /* / Chrome 10-25, Safari 5.1-6 / */
    background: -webkit-linear-gradient(
      to right,
      rgba(132, 250, 176, 0.5),
      rgba(143, 211, 244, 0.5)
    );

    /* / W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ / */
    background: linear-gradient(
      to right,
      rgba(132, 250, 176, 0.5),
      rgba(143, 211, 244, 0.5)
    );
  }

  .gradient-custom-4 {
    /* / fallback for old browsers / */
    background: #84fab0;

    /* / Chrome 10-25, Safari 5.1-6 / */
    background: -webkit-linear-gradient(
      to right,
      rgba(132, 250, 176, 0.5),
      rgba(143, 211, 244, 0.5)
    );

    /* / W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ / */
    background: linear-gradient(
      to right,
      rgba(132, 250, 176, 0.5),
      rgba(143, 211, 244, 0.5)
    );
  }
}
