/* / / For mobile devices / / */
@media (max-width: 767px) {
  /* / / Add your styles for mobile devices here  / */
  .navbar-toggler{
    display: none;
  }
  .span-logo{
    display: none;
  }
  .nav-logo{
  display: none;
  }

  
.containerr{
  position: absolute;
  left: 0;
  top: 8vh;
}
  #sidebarphotos{
    margin-right: 5px;
  }
  #sidebarscanphotos{
    margin-right: 5px;
  }
  #dashboard{
    display: none;
  }
  .sidebar{
    display: none;
  }
  .Sidebar {
    width: 100vw;
    background-color: #333;
    color: white;
    padding: 20px;
    height: 100vh;
    
  }
  .Sidebar ul {
    list-style: none;
    padding: 0;
  }
  
  .Sidebar ul li {
    margin-bottom: 10px;
  }
  
  .Sidebar a {
    text-decoration: none;
    color: white;
    
  }
  #navhead{
    height:8vh;
    color:white;
    position:fixed;
    z-index: 999;
    width: 100%;
  }
  #nomatchPhotomobile{
    padding-top: 100px;
  }
  .MitraStudioMobile{
    padding-top: 10px;
  }
  
}

/* / / For tablets / / */
@media (min-width: 768px) and (max-width: 991px) {
  /* / / Add your styles for tablets here / / */
  .navbar-toggler{
    display: none;
  }
  .MitraStudioMobile{
    padding-top: 10px;
  }
  .span-logo{
   
    display: none;
  }
  .nav-logo{
  display: none;
  }
  .containerr{
    position: absolute;
    left: 0;
    top: 8vh;
  }
    #sidebarphotos{
      margin-right: 5px;
    }
    #sidebarscanphotos{
      margin-right: 5px;
    }
    #dashboard{
      display: none;
    }
    .sidebar{
      display: none;
    }
    .Sidebar {
      width: 100vw;
      background-color: #333;
      color: white;
      padding: 20px;
      height: 100vh;
      
    }
    .Sidebar ul {
      list-style: none;
      padding: 0;
    }
    
    .Sidebar ul li {
      margin-bottom: 10px;
    }
    
    .Sidebar a {
      text-decoration: none;
      color: white;
      
    }
    #navhead{
      height:8vh;
      color:white;
      position:fixed;
      z-index: 999;
      width: 100%;
    }
    #nomatchPhotomobile{
      padding-top: 100px;
    }
    
}

/* / / For small laptops and desktops / / */
@media (min-width: 992px) and (max-width: 1199px) {
  /* / / Add your styles for small laptops and desktops here / / */
  .span-logo{
    display: none;
  }
  .nav-logo{
  display: none;
  }
  .containerr{
    position: absolute;
    left: 0;
    top: 8vh;
  }
    #sidebarphotos{
      margin-right: 5px;
    }
    #sidebarscanphotos{
      margin-right: 5px;
    }
    #dashboard{
      display: none;
    }
    .sidebar{
      display: none;
    }
    .Sidebar {
      width: 100vw;
      background-color: #333;
      color: white;
      padding: 20px;
      height: 100vh;
      
    }
    .Sidebar ul {
      list-style: none;
      padding: 0;
    }
    
    .Sidebar ul li {
      margin-bottom: 10px;
    }
    
    .Sidebar a {
      text-decoration: none;
      color: white;
      
    }
    #navhead{
      height:8vh;
      color:white;
      position:fixed;
      z-index: 999;
      width: 100%;
    }
    #nomatchPhotomobile{
      padding-top: 100px;
    }
}

/* / / For large laptops and desktops / / */
@media (min-width: 1200px) {
  /* / / Add your styles for large laptops and desktops here / / */
  .MitraStudioMobile{
    display: none;
  }
  #dashboard{
    font-size:xx-large;
   }
    .span-logo{
      margin-right: 1rem;
    }
    .nav-logo{
     height: 3rem;
     width: 3rem;
     border-radius: 5px;
    }
  #sidebarphotos{
    margin-right: 5px;
  }
  .containerr{
    height: 100%;
  }
  #sidebarscanphotos{
    margin-right: 5px;
  }
  #hamburger{
    display: none;
  }
  #navhead{
    height:15vh;
    color:white;
  }
  .sidebar{
    background-color: #333;
    color: white;
    padding: 20px;
  }

  .Sidebar {
    width: 250px;
    background-color: #333;
    color: white;
    padding: 20px;
    
  }
  .Sidebar ul {
    list-style: none;
    padding: 0;
  }
  
  .Sidebar ul li {
    margin-bottom: 10px;
  }
  
  .Sidebar a {
    text-decoration: none;
    color: white;
    
  }

  
}

