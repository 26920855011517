/* / / / For mobile devices / / / */
@media (max-width: 767px) {
  /* / / / Add your styles for mobile devices here / / / */
  .dashboardheightformobile{
   /* background: linear-gradient(to right, rgba(132, 250, 176, 0.5), rgba(143, 211, 244, 0.5)); */
   height: 100vh;
}
.photos-loader{
  padding-top: 8vh;
  text-align: center;
}
#main_flex{
  display: flex;
}
.loader{
    height: 100vh;
    text-align: center;
    padding-top: 8vh;
}
.nomatchedphotos{
    height: 100vh;
}
.toggle{
    display: none;
}

}

/* / / / For tablets / / / */
@media (min-width: 768px) and (max-width: 991px) {
  /* / / / Add your styles for tablets here / / / */
  .dashboardheightformobile{
    /* background: linear-gradient(to right, rgba(132, 250, 176, 0.5), rgba(143, 211, 244, 0.5)); */
    height: 100vh;
 }
 .photos-loader{
   padding-top: 8vh;
   text-align: center;
 }
 #main_flex{
   display: flex;
 }
 .loader{
     height: 100vh;
     text-align: center;
     padding-top: 8vh;
 }
 .nomatchedphotos{
     height: 100vh;
 }
 .toggle{
     display: none;
 }
}

/* / / / For small laptops and desktops / / / */
@media (min-width: 992px) and (max-width: 1199px) {
  /* / / / Add your styles for small laptops and desktops here / / / */
  .dashboardheightformobile{
    /* background: linear-gradient(to right, rgba(132, 250, 176, 0.5), rgba(143, 211, 244, 0.5)); */
    height: 100vh;
 }
 .photos-loader{
   padding-top: 8vh;
   text-align: center;
 }
 #main_flex{
   display: flex;
 }
 .loader{
     height: 100vh;
     text-align: center;
     padding-top: 8vh;
 }
 .nomatchedphotos{
     height: 100vh;
 }
 .toggle{
     display: none;
 }

}
@media (min-width: 1201px) and (max-width: 1299px){
  /* #dashboardheightfix{
    height: 100%;
  } */
}
/* / / / For large laptops and desktops / / / */
@media (min-width: 1200px) {
  /* / / / Add your styles for large laptops and desktops here / / / */
  .dashboardheightformobile{
    /* background: linear-gradient(to right, rgba(132, 250, 176, 0.5), rgba(143, 211, 244, 0.5)); */

    height: auto;
 }
 .photos-loader{
  text-align: center;
 }
 #dashboardheightfix{
  height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
}
 #main_flex{
  
  height: 100%;
 }
 .main_flex{
  display: flex;
 }
 #main_flex_for2{
  height: 100vh;
 }

 .imageandlink{
  height: 300px;
 }
 .loader{
    text-align: center;
    height: 100vh;
   }
 .nomatchedphotos{
    height: 100vh;
}
.loader1 {
  width: 215px;
  height: 215px;
  display: block;
  margin: auto;
  position: relative;
  background: #FFF;
  box-sizing: border-box;
}
.loader1::after {
  content: '';
  width: calc(100% - 30px);
  height: calc(100% - 15px);
  top: 15px;
  left: 15px;
  position: absolute;
  background-image: linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.5) 50%, transparent 100%),
   linear-gradient(#DDD 100px, transparent 0),
   linear-gradient(#DDD 16px, transparent 0),
   linear-gradient(#DDD 50px, transparent 0);
  background-repeat: no-repeat;
  background-size: 75px 175px, 100% 100px, 100% 16px, 100% 30px;
  background-position: -185px 0, center 0, center 115px, center 142px;
  box-sizing: border-box;
  animation: animloader 1s linear infinite;
}

@keyframes animloader {
  to {
    background-position: 185px 0, center 0, center 115px, center 142px;
  }
}
}
