*{
    margin: 0px;
    padding: 0px;
}

.containerr {
    display: flex;
    height: auto;
    /* border: 10px solid red */
  }
  
  .sidebar {
    width: 250px;
    background-color: #333;
    color: white;
    padding: 20px;
    
  }
  
  .sidebar ul {
    list-style: none;
    padding: 0;
  }
  
  .sidebar ul li {
    margin-bottom: 10px;
  }
  
  .sidebar a {
    text-decoration: none;
    color: white;
    
  }
  
  /* .content {
    flex: 1;
    padding: 20px;
    border: 10px solid red;
  } */
  